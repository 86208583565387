import React from "react";
import {
  whitelogo,
  whitePlaystore,
  whiteAppatore,
  facebookFotterIcon,
  instaFotterIcon,
  twitterFotterIcon,
  lindlinIconFotter,
} from "../../../constants/images";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-20 bg-[#001E27] text-[#FFFFFF]">
      <div className="mx-10 flex flex-col gap-y-10 pb-10 pt-20 md:mx-10 md:flex md:flex-row lg:mx-36 lg:flex lg:flex-row ">
        <div className=" flex flex-col gap-y-10 md:w-[38%] lg:w-[40%] ">
          <img src={whitelogo} alt="" className="w-32" />
          <p className="text-[16px] md:w-[65%] lg:w-[65%] ">
            At GYWDE, we aim to empower individuals to seamlessly connect with skilled vendors and
            get their work done with confidence.
          </p>
          <div className="hidden md:flex md:gap-2 lg:flex lg:gap-4">
            <Link to="https://x.com/gywdeofficial" target="_blank" className="cursor-pointer">
              <img src={twitterFotterIcon} alt="" />
            </Link>
            <Link to="https://www.instagram.com/gywde/" target="_blank" className="cursor-pointer">
              <img src={instaFotterIcon} alt="" />
            </Link>
            <Link to="#" target="_blank" className="cursor-pointer">
              <img src={facebookFotterIcon} alt="" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/gywde"
              target="_blank"
              className="cursor-pointer"
            >
              <img src={lindlinIconFotter} alt="" />
            </Link>
          </div>
          <div className="hidden md:flex md:gap-2 lg:flex  lg:gap-4">
            <img src={whiteAppatore} alt="" className="md:w-[6rem] lg:w-[7rem]" />
            <img src={whitePlaystore} alt="" className="md:w-[6rem] lg:w-[7rem]" />
          </div>
        </div>
        <div className="grid w-[60%] grid-cols-1 gap-5 md:grid md:grid-cols-3 lg:grid lg:grid-cols-3">
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Categories</p>
            <Link to="/coming-soon" className="group w-fit">
              Repairs and maintenance
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Digital services
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Events services
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Beauty services
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Transport and logistics
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Consulting services
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
          </div>
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Company</p>
            <Link to="/about-us" className="group w-fit">
              About Us
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              FAQ
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>

            <Link to="/privacy-policy.pdf" target="_blank" className="group w-fit">
              Privacy policy
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
          </div>
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Contact Us</p>
            <Link to="mailto:support@gywde.com" target="_blank" className="group w-fit">
              support@gywde.com{" "}
              <div class="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
          </div>
        </div>
        <div className="flex gap-2 md:hidden lg:hidden">
          <Link to="https://x.com/gywdeofficial" target="_blank" className="cursor-pointer">
            <img src={twitterFotterIcon} alt="" />
          </Link>
          <Link to="https://www.instagram.com/gywde/" target="_blank" className="cursor-pointer">
            <img src={instaFotterIcon} alt="" />
          </Link>
          <Link to="#" target="_blank" className="cursor-pointer">
            <img src={facebookFotterIcon} alt="" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/gywde"
            target="_blank"
            className="cursor-pointer"
          >
            <img src={lindlinIconFotter} alt="" />
          </Link>
        </div>
        <div className="flex gap-2 md:hidden lg:hidden">
          <img src={whiteAppatore} alt="" className="md:w-[6rem] lg:w-[7rem]" />
          <img src={whitePlaystore} alt="" className="md:w-[6rem] lg:w-[7rem]" />
        </div>
      </div>

      <div className="w-full border-t-4 border-[#002D3A]"></div>
      <div className=" mx-10 flex justify-start py-8 md:items-center md:justify-center lg:items-center lg:justify-center">
        <p className="">© Gywde 2025, All Right Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
