import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPermissionDetails,
  setRefreshPermission,
  setShowAddPermission,
} from "../../../../../../redux/slice/modalOverlay";

const PermissionsTable = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.token.states.accessToken);
  const refreshPermission = useSelector((state) => state.modalOverlay.states.refreshPermission);
  const [permissions, setPermissions] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [selectedPermissionId, setSelectedPermissionId] = useState(null);

  const formatRole = (role) => {
    const fetchedRoleMapping = {
      super_admin: "Super Admin",
      dispute_resolution: "Dispute Resolution",
      id_confirmation: "ID Confirmation",
      sales: "Sales",
    };
    return fetchedRoleMapping[role] || role;
  };

  const fetchPermissions = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/get-permissoned-users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setPermissions(data.users);
    } catch (error) {}
  };

  useEffect(() => {
    fetchPermissions();
  }, [refreshPermission]);

  const handleDeletePermission = async (id) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/delete-permission/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ id }),
        },
      );
      setShowAction(false);
      fetchPermissions();
      dispatch(setRefreshPermission(false))
      if (!response.ok) {
        throw new Error("Failed to delete permission");
      }
    } catch (error) {
    } finally {
    }
  };

  const handleEditAgent = (value) => {
    dispatch(setPermissionDetails(value));
    dispatch(setShowAddPermission(true));
    setShowAction(false);
  };

  const handleAction = (id) => {
    setSelectedPermissionId(id);
    setShowAction(!showAction);
  };

  return (
    <div className="flex w-full flex-col">
      {permissions.length === 0 && (
        <span className="mx-auto text-center text-secondary lg:text-lg">
          No permissions added yet.
        </span>
      )}
      {permissions.length !== 0 && (
        <div className="grid w-full grid-cols-4 justify-items-center bg-[#E8E8E8] px-6 py-4 text-lg font-medium text-secondary">
          <span>Name</span>
          <span>Email</span>
          <span>Permission</span>
          <span>Action</span>
        </div>
      )}
      {permissions.map((permission) => (
        <div className="relative" key={permission.id}>
          <div className="relative transition-colors ease-in-out">
            <div className="grid w-full grid-cols-4 justify-items-center px-6 py-4">
              <span className="">
                {permission.first_name} {permission.last_name}
              </span>
              <span className="">{permission.email}</span>
              <span className="">{formatRole(permission.user_type)}</span>
              <span className="cursor-pointer" onClick={() => handleAction(permission.id)}>
                ...
              </span>
            </div>
            <hr color="#AEAEAE" className="w-full" />
          </div>
          {showAction && selectedPermissionId === permission.id && (
            <div className="absolute right-10 top-0 rounded-[5px] bg-primary py-1">
              <div className="flex w-full flex-col justify-start">
                <span
                  className="w-full px-10 text-secondary hover:cursor-pointer hover:bg-[#eee]"
                  onClick={() => handleEditAgent(permission)}
                >
                  Edit
                </span>
                <span
                  className="w-full px-10 text-secondary hover:cursor-pointer hover:bg-[#eee]"
                  onClick={() => handleDeletePermission(permission.id)}
                >
                  Delete
                </span>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PermissionsTable;
