import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../../components";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Dropdown, Fund } from "../../../../../constants/images";
import { useDispatch } from "react-redux";
import { setModalOverlay } from "../../../../../redux/slice/modalOverlay";

const VendorChart = ({ registeredVendor }) => {
  const dispatch = useDispatch();
  const [selectedWeek, setSelectedWeek] = useState("This week");
  const [openSelect, setOpenSelect] = useState(false);

  const chartData = registeredVendor?.map((vendor) => ({
    name: vendor.name,
    uv: vendor.gender === "male" ? 1 : 0,
    pv: vendor.gender === "female" ? 1 : 0,
  }));
  const handleFundClick = () => {
    dispatch(setModalOverlay(true));
  };
  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };
  const maleVendorCount = registeredVendor?.filter((vendor) => vendor?.gender === "male").length;
  const femaleVendorCount = registeredVendor?.filter(
    (vendor) => vendor?.gender === "female",
  ).length;

  return (
    <div className="mx-auto mb-10 flex w-full rounded-[10px] bg-primary py-4">
      <div className="flex w-full flex-col">
        <div className="flex w-full items-center justify-between px-10 py-5">
          <span className="font-medium text-secondary md:text-lg">Registered Vendors</span>
          <div className="relative flex items-center gap-x-2">
            <div
              onClick={handleSelect}
              className="flex w-[190px] items-center justify-between rounded-[5px] border border-[#AEAEAE] px-5 py-2 text-base font-medium text-lightgray hover:cursor-pointer"
            >
              <span>{selectedWeek}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute left-0 top-[100%] w-full animate-slide_up rounded-[10px] bg-primary px-3 py-4">
                <div className="flex flex-col items-center gap-y-2 text-base font-medium text-lightgray">
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedWeek("This week")}
                  >
                    This week
                  </span>

                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedWeek("This month")}
                  >
                    This month
                  </span>
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedWeek("This year")}
                  >
                    This year
                  </span>
                  <span
                    className="text-lightblue hover:cursor-pointer"
                    onClick={() => setSelectedWeek("Custom range")}
                  >
                    Custom range
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className="w-full px-0" color="#AEAEAE" />
        <div className="flex w-3/4 gap-x-8 p-10">
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#279F51]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">Male</span>
              <span className="font-semibold text-secondary md:text-2xl">
                {maleVendorCount || 0}
              </span>
            </div>
          </div>
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#623CEA]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">Female</span>
              <span className="font-semibold text-secondary md:text-2xl">
                {femaleVendorCount || 0}
              </span>
            </div>
          </div>
        </div>
        <BarChart
          width={1200}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 50,
            left: 50,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="pv" barSize={20} stackId="a" fill="#279F51" />
          <Bar dataKey="uv" barSize={20} stackId="a" fill="#623CEA" />
        </BarChart>
      </div>
    </div>
  );
};

export default VendorChart;
