import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAgentId, setShowEditAgent } from "../../../../../../redux/slice/modalOverlay";

const AgentTable = ({ fetchAllAgent, allAgent }) => {
  const agentDetails = useSelector((state) => state.modalOverlay.states.agentDetails);
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const [details, setDetails] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);

  const dispatch = useDispatch();

  const deleteAgent = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/agent`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ agentId: selectedAgentId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete agent");
      }

      const data = await response.json();

      fetchAllAgent();
    } catch (error) {
      console.log("Error deleting agent", error);
    } finally {
      // setShowModal(false);
    }
  };

  const handleEditAgent = (agent) => {
    dispatch(setShowEditAgent(true));
    dispatch(setAgentId(agent));
  };

  const handleAction = (agentId) => {
    setSelectedAgentId(agentId);
    setShowAction(!showAction);
  };

  useEffect(() => {
    fetchAllAgent();
  }, []);

  useEffect(() => {
    setDetails(agentDetails);
  }, [agentDetails]);

  return (
    <div className="flex w-full flex-col">
      {allAgent.length === 0 && (
        <span className="mx-auto text-center text-secondary lg:text-lg">No agents yet.</span>
      )}
      {allAgent.length !== 0 && (
        <div className="grid w-full grid-cols-4 justify-items-center bg-[#E8E8E8] px-6 py-4 text-lg font-medium text-secondary">
          <span>Name</span>
          <span>Company name</span>
          <span>Number of referrals</span>
          <span>Action</span>
        </div>
      )}
      {allAgent.length !== 0 &&
        allAgent.map((value) => (
          <div className="relative" key={value.id}>
            <div className="relative transition-colors ease-in-out">
              <div className="grid w-full grid-cols-4 justify-items-center px-6 py-4">
                <span className="">{value?.agent_name}</span>
                <span className="">{value?.company_name}</span>
                <span className="">{value?.number_of_referrals}</span>
                <span className="cursor-pointer" onClick={() => handleAction(value.id)}>
                  ...
                </span>
              </div>
              <hr color="#AEAEAE" className="w-full" />
            </div>
            {showAction && selectedAgentId === value.id && (
              <div className="absolute right-10 top-0 rounded-[5px] bg-primary py-1">
                <div className="flex w-full flex-col justify-start">
                  <span
                    className="w-full px-10 text-secondary hover:cursor-pointer hover:bg-[#eee]"
                    onClick={() => handleEditAgent(value)}
                  >
                    View
                  </span>
                  <span
                    className="w-full px-10 text-secondary hover:cursor-pointer hover:bg-[#eee]"
                    onClick={() => deleteAgent()}
                  >
                    Delete
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default AgentTable;
